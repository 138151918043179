import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useContext, useEffect, useState } from "react";
import data from "../../data/data.json";
// import { QueryContext } from "../../context/QueryContext";
import { ThemeContext } from "../../context/ThemeContext";
import CardImage from "../../components/CardImage";
import { Icon } from "@iconify/react/dist/iconify.js";
import Footer from "../../components/Footer";
// import { getByQuery } from "../../api/MOCK";
// import Card from "../../components/Card";
import lS from "manager-local-storage";
import "./styles/Pack.css";
import "./styles/Pack-mobile.css";
import { Helmet } from "react-helmet";
import { PremiumContext } from "../../context/PremiumContext";

function Pack() {
  const { nick } = useParams();

  const [modelData, setModelData] = useState(null);
  // const [items, setItems] = useState([]);
  const [favorite, setFavorite] = useState(false);
  const [shortener, setShortener] = useState("");
  const [options, setOptions] = useState([]);
  const [showTempElement, setShowTempElement] = useState(false);

  // const { query } = useContext(QueryContext);
  const { theme: t, autoTheme } = useContext(ThemeContext);
  // const { query } = useContext(QueryContext);

  const { premium, setPremium } = useContext(PremiumContext);

  const favoriteKey = "mega-packs-favorites";

  // const setup = useCallback(async () => {
  //   let fetchedItems;
  //   try {
  //     if (query) {
  //       fetchedItems = await getByQuery(query);
  //     }

  //     if (fetchedItems.error) {
  //       throw new Error("Network Error");
  //     }
  //     setItems(fetchedItems);
  //   } catch (error) {}
  // }, [query]);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth", // ou 'auto' para rolar sem animação
    });
  };

  const theme = t === "device" ? autoTheme : t;
  // const emptyQuery = query && items.length === 0;

  useEffect(() => {
    const getModelData = () => {
      const model = data.find((d) => {
        const nicks = d.nick.split(" ");

        return nicks.some((n) => n === nick);
      });

      const optionsValues = Object.keys(model.links).reverse();
      optionsValues.shift();
      setModelData(model);
      setOptions(optionsValues);
      setShortener(optionsValues[0]);
    };
    const getFavorite = () => {
      const currentFavorites = lS.get(favoriteKey) ?? [];
      const isFavorite = currentFavorites.some((f) => f === nick);
      setFavorite(isFavorite);
    };
    const getIsPremium = () => {
      const premium = lS.get("premium");
      if (premium) setPremium(true);
    };
    getIsPremium();
    getModelData();
    getFavorite();
    // setup();
    scroll();
  }, [nick, setPremium]);

  const handleFavorite = () => {
    const currentFavorites = lS.get(favoriteKey) ?? [];
    if (favorite) {
      const removedFavorite = currentFavorites.filter(
        (f) => f !== modelData.nick
      );
      if (removedFavorite.length === 0) {
        lS.remove(favoriteKey);
      }
      lS.set(favoriteKey, removedFavorite);
      setFavorite(false);
    } else {
      currentFavorites.push(modelData.nick);
      lS.set(favoriteKey, currentFavorites);
      setFavorite(true);
    }
    // setup();

    setShowTempElement(true);
    // Esconder o elemento temporário após 1 segundo
    setTimeout(() => {
      setShowTempElement(false);
    }, 1000);
    scroll();
  };

  const handleChangeShortener = ({ target: { value } }) => {
    setShortener(value);
  };

  const shortenerName = {
    cuty: "Cuty.io",
    shrtfly: "Shortfly",
  };

  const type = () => {
    const simple = "simple-icons:";

    if (modelData.linkType) {
      if (modelData.linkType === "terabox") return "ri:t-box-fill";
      return `${simple}${modelData.linkType}`;
    } else return `${simple}mega`;
  };

  return (
    <main className="pack">
      <Header />
      {/* {query && (
        <section className="home__cards">
          {items.map((data, i) => {
            return <Card data={data} key={i} i={i} setup={setup} />;
          })}
          {emptyQuery && (
            <p className="home__cards__empty-query">
              Nenhum conteúdo correspondente
            </p>
          )}
        </section>
      )} */}
      {modelData && (
        <section
          className={`pack-content${modelData.popular ? " --popular" : ""}`}
        >
          <Helmet>
            <title>Pack - {modelData.name}</title>
          </Helmet>

          <div className="pack__pack-content__right-content">
            <div
              className={`pack__pack-content__right-content__name c-${theme}`}
            >
              <h1 className={`c-${theme}`}>
                {/* <Icon icon="icon-park-twotone:peach" /> */}
                {`Pack ${modelData.name} - Gratuito`}
                {/* <Icon icon="mdi:tick" className="card__info__name__verified" /> */}
              </h1>
              <span className={`c-${theme}`}>{nick}</span>
            </div>

            {/* <button
              className="pack__pack-content__right-content__buttons__like"
              onClick={handleFavorite}
            >
              Adicionar aos Favoritos
              <Icon
                icon={
                  favorite
                    ? "material-symbols:heart-check-rounded"
                    : "ph:heart-bold"
                }
              />
            </button> */}
            {/* <div className="pack__pack-content__right-content__buttons">
              <Link
                className="card__info__link pack__pack-content__right-content__buttons__link"
                rel="noreferrer"
                to={modelData.links[shortener]}
                target="_blank"
              >
                Acessar
                <Icon
                  icon={
                    modelData.type && modelData.type === "telegram"
                      ? "simple-icons:telegram"
                      : "simple-icons:mega"
                  }
                />
              </Link>
              <label
                htmlFor="shortener"
                className={`pack__pack-content__right-content__options bg-${theme}-2 c-${theme}`}
              >
                {shortenerName[shortener]}
                <Icon icon="weui:arrow-filled" rotate={1} />
                <select
                  name="shortener"
                  id="shortener"
                  onChange={handleChangeShortener}
                  className={`bg-${theme} c-${theme}`}
                >
                  {options.map((o) => (
                    <option value={o}>{shortenerName[o]}</option>
                  ))}
                </select>
              </label>
            </div> */}
            <div className="pack-images">
              <CardImage
                images={modelData.thumbnail}
                onDbClick={handleFavorite}
                favorite={favorite}
                showTempElement={showTempElement}
                slidesToShow={1}
              />
            </div>
          </div>
          {/* <h2 className={`c-${theme} pack__pack-content__right-content__title`}>
            Acessar{" "}
            <span className="pack__pack-content__right-content__title__site">
              {modelData.site}
            </span>{" "}
            da modelo{" "}
            <span className="pack__pack-content__right-content__title__site">
              {modelData.name}
            </span>{" "}
            completo GRÁTIS!
          </h2> */}
          {/* <div className="pack__pack-content__right-content__buttons">
            <Link
              className="card__info__link pack__pack-content__right-content__buttons__link"
              rel="noreferrer"
              to={modelData.links[shortener]}
              target="_blank"
            >
              Acessar
              <Icon
                icon={
                  modelData.type && modelData.type === "telegram"
                    ? "simple-icons:telegram"
                    : "simple-icons:mega"
                }
              />
            </Link>
            <label
              htmlFor="shortener"
              className={`pack__pack-content__right-content__options bg-${theme}-2 c-${theme}`}
            >
              {shortenerName[shortener]}
              <Icon icon="weui:arrow-filled" rotate={1} />
              <select
                name="shortener"
                id="shortener"
                onChange={handleChangeShortener}
                className={`bg-${theme} c-${theme}`}
              >
                {options.map((o, i) => (
                  <option value={o} key={i}>
                    {shortenerName[o]}
                  </option>
                ))}
              </select>
            </label>
          </div> */}
          <div className={`link${premium ? " --premium" : ""}`}>
            {!premium && (
              <label
                htmlFor="shortener"
                className={`pack__pack-content__right-content__options bg-${theme}-2 c-${theme}`}
              >
                {shortenerName[shortener]}
                <Icon icon="weui:arrow-outlined" rotate={1} />
                <select
                  name="shortener"
                  id="shortener"
                  onChange={handleChangeShortener}
                  className={`bg-${theme} c-${theme}`}
                >
                  {options.map((o, i) => (
                    <option value={o} key={i}>
                      {shortenerName[o]}
                    </option>
                  ))}
                </select>
              </label>
            )}
            <Link
              className={`link__type`}
              rel="noreferrer"
              to={modelData.links[premium ? "mega" : shortener]}
              target="_blank"
            >
              <Icon
                icon={type()}
                // className={!isMega ? "--telegram" : "--mega"}
              />
              <div className="link__type__btn">
                Acessar
                <Icon icon="ph:arrow-up-light" rotate={1} />
              </div>
            </Link>
          </div>
        </section>
      )}
      <Footer />
    </main>
  );
}

export default Pack;
