import { useContext } from "react";
import { QueryContext } from "../../context/QueryContext";
// import logo from "../../assets/logo.png";
import logo from "../../assets/logo5.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ThemeContext } from "../../context/ThemeContext";
import "./styles/Header.css";
import "./styles/Header-mobile.css";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const { query, setQuery } = useContext(QueryContext);
  const { theme: t, autoTheme } = useContext(ThemeContext);

  const navigate = useNavigate();

  const theme = t === "device" ? autoTheme : t;

  const onQueryChange = ({ target }) => {
    const { value } = target;
    setQuery(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${query}`)
  }

  return (
    <header className={`header bg-${theme}`}>
      <Link to="/" className="header__logo">
        <img src={logo} alt="" className="header__logo__img" />
        <h1 className="header__logo__text">
          <span className="header__logo__text__only">Mega</span>
          <span className="header__logo__text__free">Packs</span>
        </h1>
      </Link>
      <form method="GET" className="header__search" onSubmit={onSubmit}>
        <input
          type="text"
          name="search"
          id="search"
          value={query}
          onChange={onQueryChange}
          className={`header__search__input bg-${theme}-2 c-${theme}`}
          placeholder="Encontre o que procura..."
        />
        <button
          className={`header__search__search-icon bg-${theme}-2`}
          onClick={onSubmit}
        >
          <Icon
            icon="lets-icons:search"
            className="header__search__search-icon__icon"
            rotate={1}
          />
        </button>
      </form>
      {/* <div className="header__links">
        <a
          href="https://t.me/+V6Kyta4xTeFmZGYx"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="mingcute:telegram-line" />
        </a>
      </div> */}
    </header>
  );
}

export default Header;
