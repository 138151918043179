import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryProvider } from "./context/QueryContext";
import { ThemeProvider } from "./context/ThemeContext";
// import { Analytics } from "@vercel/analytics/react";
import "./index.css";
import { PremiumProvider } from "./context/PremiumContext";
// import Snowflakes from "./components/Snowflakes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <PremiumProvider>
      <ThemeProvider>
        <QueryProvider>
          {/* <Analytics /> */}
          {/* <Snowflakes /> */}
          <App />
        </QueryProvider>
      </ThemeProvider>
    </PremiumProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
