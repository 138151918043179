import { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import Card from "../../components/Card";
import Header from "../../components/Header";
import { ThemeContext } from "../../context/ThemeContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { formatNumericInput } from "../../helpers/formatNumericInput";
import { get, getPages } from "../../api/MOCK";
import Footer from "../../components/Footer";
import lS from "manager-local-storage";
import DbClickNotify from "../../components/DbClickNotify";
// import Loading from "../../components/Loading";
import "./styles/Home.css";
import Banner from "../../components/Banner";

function Home() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const page = params.get("page") ? Number(params.get("page")) : 1;
  const [inputPage, setInputPage] = useState(page);
  // const [loading, setLoading] = useState(false);

  const { theme: t, autoTheme } = useContext(ThemeContext);

  const theme = t === "device" ? autoTheme : t;

  const numberInputPage = Number(inputPage);

  const DEFAULT_SORT = "popular";

  const initialSort = lS.get("mega-packs-sort") ?? DEFAULT_SORT;

  const [items, setItems] = useState([]);
  const [pages, setPages] = useState(0);
  const [sort, setSort] = useState(initialSort);

  const setup = useCallback(async () => {
    try {
      const fetchedItems = await get(page, sort);
      if (fetchedItems.error) {
        throw new Error("Network Error");
      }
      setItems(fetchedItems);
    } catch (error) {}

    const fetchedPages = await getPages();
    setPages(Number(fetchedPages));
    setInputPage(page);
  }, [page, sort]);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // ou 'auto' para rolar sem animação
    });
  };

  useEffect(() => {
    setup();
    scroll();
  }, [page, sort, setup]);

  const disabledPrev = page === 1;
  const disabledNext = page === pages;

  const handlePrevPage = (e) => {
    e.preventDefault();
    if (!disabledPrev) {
      navigate(`/?page=${page - 1}`);
      params.set("page", page - 1);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (!disabledNext) {
      navigate(`/?page=${page + 1}`);
    }
  };

  const handleChangeInputPage = ({ target }) => {
    const { value } = target;
    const newValue = formatNumericInput(value);
    setInputPage(newValue);
  };

  const handleInputPage = (e) => {
    e.preventDefault();
    if (numberInputPage > 0 && numberInputPage <= pages) {
      navigate(`/?page=${numberInputPage}`);
    }
  };

  const selectedSort = (value) => {
    if (value === sort) return " --selected";
    return "";
  };

  const handleChangeSort = ({ target }) => {
    const { value } = target;
    if (value === DEFAULT_SORT) {
      lS.remove("mega-packs-sort");
    } else {
      lS.set("mega-packs-sort", value);
    }
    setSort(value);
    navigate("/");
  };

  // const handleLoad = () => {
  //   setLoading(true);
  // };

  // const classLoad = loading ? "complete-load" : "in-load";

  return (
    <main className={`home bg-${theme}`}>
      <Header />
      <section className="home__welcome-section">
        {/* <div className={`home__welcome-section__title c-${theme}`}>
          Bem Vindo(a) ao{" "}
          <h1 className="header__logo__text" style={{ fontSize: "1em" }}>
            <span
              className=""
              style={{ fontFamily: "product sans" }}
            >
              mega
            </span>
            <span
              className=""
              style={{ fontFamily: "product sans" }}
            >
              packs
            </span>
            .fun
          </h1>
        </div>
        <p className={`home__welcome-section__slogan c-${theme}`}>
          A maior biblioteca de OnlyFans grátis!
        </p> */}
        <Banner pages={pages} />
        <div className="home__welcome-section__links">
          <a
            href="https://t.me/+V6Kyta4xTeFmZGYx"
            target="_blank"
            rel="noreferrer"
            className={`home__welcome-section__links__button bg-${theme}-2`}
          >
            <Icon icon="basil:telegram-outline" />
            <span className={`c-${theme}`}>Junte-se ao nosso canal!</span>
          </a>
          <Link
            to="/gallery"
            rel="noreferrer"
            className={`home__welcome-section__links__button bg-${theme}-2`}
          >
            <Icon icon="line-md:compass-loop" />
            <span className={`c-${theme}`}>Explorar</span>
          </Link>
        </div>
      </section>
      <section className="home__sort">
        <label
          htmlFor="popular"
          className={`${selectedSort("popular")} c-${theme}`}
        >
          <Icon icon="mdi:fire" hFlip />
          <span className="home__sort__text">Hot</span>
          <input
            onChange={handleChangeSort}
            type="radio"
            value="popular"
            name="sort"
            id="popular"
          />
        </label>
        <label
          htmlFor="favorites"
          className={`${selectedSort("favorites")} c-${theme}`}
        >
          <Icon icon="material-symbols:favorite" />
          <span className="home__sort__text">Favoritos</span>
          <input
            onChange={handleChangeSort}
            type="radio"
            value="favorites"
            name="sort"
            id="favorites"
          />
        </label>
        <label htmlFor="date" className={`${selectedSort("date")} c-${theme}`}>
          <Icon icon="tabler:clock-hour-7" />
          <span className="home__sort__text">Recentes</span>
          <input
            onChange={handleChangeSort}
            type="radio"
            value="date"
            name="sort"
            id="date"
          />
        </label>
        <label htmlFor="az" className={`${selectedSort("az")} c-${theme}`}>
          <Icon icon="tabler:sort-a-z" fontSize={"1.5em"} />
          <input
            onChange={handleChangeSort}
            type="radio"
            value="az"
            name="sort"
            id="az"
          />
        </label>
        <label htmlFor="za" className={`${selectedSort("za")} c-${theme}`}>
          <Icon icon="tabler:sort-z-a" fontSize={"1.5em"} />
          <input
            onChange={handleChangeSort}
            type="radio"
            value="za"
            name="sort"
            id="za"
          />
        </label>
      </section>
      <section className={`home__cards`}>
        {items.map((data, i) => {
          return <Card data={data} key={i} i={i} setup={setup} />;
        })}
      </section>
      {sort !== "favorites" && (
        <div className={`home__pages`}>
          <button
            className="home__pages__page-btn-arrow"
            onClick={handlePrevPage}
            disabled={disabledPrev}
          >
            <Icon icon="ic:round-play-arrow" rotate={2} />
          </button>
          <form onSubmit={handleInputPage}>
            <input
              type="text"
              onChange={handleChangeInputPage}
              value={inputPage}
              className={`bg-${theme}-2 c-${theme}`}
            />
          </form>
          <div></div>
          <span>{pages}</span>
          <button
            className="home__pages__page-btn-arrow"
            onClick={handleNextPage}
            disabled={disabledNext}
          >
            <Icon icon="ic:round-play-arrow" />
          </button>
        </div>
      )}
      {/* <Loading className={classLoad} /> */}
      <DbClickNotify />
      <Footer />
    </main>
  );
}

export default Home;
